const REFLECTIONS = [
  {
    id: 3,
    title: "Filling the Void Through Seeking Connection and Emotional Refuge in Others",
    date: Date.parse("June 01, 2024"),
    description: "Loneliness and the search for safe space",
    tags: ["Loneliness"],
    path: "loneliness",
    slug: "/reflections/loneliness",
    social_image: "",
    keywords:
      "Loneliness, Connection, Emotional Refuge, Safe Space, Emotional Support, Loneliness and Isolation, Mental Health, Social Interaction, Emotional Well-being, Emotional Health, Loneliness and Relationships, Loneliness and Mental Health, Loneliness and Mental Illness, Loneliness and Depression, Loneliness and Anxiety, Loneliness and Stress, Loneliness and Emotional Health, aayush, aayush chaudhary, thearchetypee",
  },
  {
    id: 1,
    title:
      "Navigating the Emotional Terrain: The Power of Emotional Agility in the Professional Field",
    date: Date.parse("March 18, 2023"),
    description:
      "Explore the impact of emotional agility on professional success",
    tags: [],
    path: "emotional-agility",
    slug: "/reflections/emotional-agility",
    social_image: "/assets/reflections/emotional-agility/header-image.jpg",
    keywords:
      "Professional world, stress, emotions, negative feelings, frustration, anxiety, self-doubt, inner thoughts, self-management strategies, emotional agility, case study, Product Manager, tech firm, inner critic, Recognize patterns, Label thoughts and emotions, Acceptance, Act on values, Resilience, Stress reduction, Burnout prevention, Well-being, Performance enhancement, Emotional terrain, Confidence, Fulfillment, Success, aayush, aayush chaudhary, thearchetypee",
  },
  {
    id: 2,
    title: "The Elusive Pursuit of Happiness: Rethinking Our Approach",
    date: Date.parse("February 16, 2024"),
    description:
      " Let’s take a pause and reflect on our personal pursuit of happiness",
    tags: [],
    path: "pursuit-of-happiness",
    slug: "/reflections/pursuit-of-happiness",
    social_image:
      "/assets/reflections/pursuit-of-happiness/pursuit-of-happiness.jpg",
    keywords:
      "Happiness, Contentment, Pursuit of Happiness, Misconception of Happiness, Psychological Perspective on Happiness, Positive Psychology, Seligman, Resilience, Joy, Small Joys, Personal Happiness, Meaningful Relationships, Psychological Well-being, Emotional Intelligence, Gratitude, Positive Emotions, Accomplishment, Engagement, Finding Happiness, Self-discovery, aayush, aayush chaudhary, thearchetypee",
  },
];

export default REFLECTIONS;
